body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom Toast Styles */
.custom-toast {
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.9) -5.91%, rgba(74, 47, 189, 0.9) 111.58%) !important;
  border-radius: 12px !important;
  font-family: 'Centra', sans-serif !important;
  padding: 8px !important;
  position: relative !important;
}

.custom-toast-body {
  color: #fff !important;
  font-size: 14px !important;
  padding: 12px 24px 12px 12px !important;
}

.custom-toast-progress {
  background: rgba(255, 255, 255, 0.7) !important;
}

/* Override close button styles */
.Toastify__close-button {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
  color: #fff !important;
  opacity: 0.7;
  padding: 0 !important;
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.Toastify__close-button:hover {
  opacity: 1;
}

.Toastify__close-button > svg {
  width: 14px !important;
  height: 14px !important;
}
