:root {
  --tg-body-font-family: 'Outfit', sans-serif;
  --tg-heading-font-family: 'Plus Jakarta Sans', sans-serif;
  --tg-primary-color: #44A08D;
  --tg-secondary-color: #0F101E;
  --tg-body-font-color: #92939E;
  --tg-heading-font-color: #FFFFFF;
  --tg-paragraph-color: #92939E;
  --tg-body-font-size: 16px;
  --tg-body-font-weight: 400;
  --tg-heading-font-weight: 600;
  --tg-body-line-height: 1.62;
  --tg-heading-line-height: 1.2;
  --tg-blue: #0d6efd;
  --tg-indigo: #6610f2;
  --tg-purple: #6f42c1;
  --tg-pink: #d63384;
  --tg-red: #dc3545;
  --tg-orange: #fd7e14;
  --tg-yellow: #ffc107;
  --tg-green: #093637;
  --tg-teal: #20c997;
  --tg-cyan: #0dcaf0;
  --tg-white: #ffffff;
  --tg-black: #010314;
  --tg-gray: #93B2C8;
}
/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
/*  padding: 0px 0;*/
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
    min-width: 150px;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
  line-height: 40px;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}

.download-bx .social-icon a {
  width: 62px;
  height: 62px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.download-bx .social-icon a::before {
  content: "";
  width: 62px;
  height: 62px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}

.social-icon a svg {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
  color: #5dfff5;
}

.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.social-icon a:hover svg {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #5dfff5;
  border: 2px solid #5dfff5;
  padding: 11px 30px;
  font-size: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  border-radius: 3em;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
  background: #5dfff5;
  border-radius: 3em;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner .animated {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
  color: #5dfff5;
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 76%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #0d6efd -5.91%, #0dcaf0 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background-image: linear-gradient(to bottom, rgba(95, 255, 245, 0.52), rgba(0, 0, 0, 0.53)),
    url('./assets/img/roadmap_bg.png');
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
/*  background-image: url('./assets/img/footer-bg.png');*/
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.download-bx {
  background: #5dfff54f;
  border-radius: 55px;
  color: #121212;
  padding: 65px 105px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.download-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
  color: #ffffff;
}
.download-bx a{
  color: #fff;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  border: solid 1px #333;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: #333;
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}



@media (min-width: 579.98px){
  .roadmap-tl {
      max-width: 1100px;
      padding-top: 20px;
  }
  .roadmap-tl-phase {
    padding: 25px 0 50px;
  }
  .roadmap-row:nth-child(odd) {
    margin-bottom: 140px;
    padding-right: 65px;
  }
  .roadmap-row:not(:last-child) {
    margin-bottom: 0;
  }
  .roadmap-row {
    clear: left;
    float: left;
    padding-left: 0;
    width: 50%;
  }

  .roadmap-row:nth-child(odd) .roadmap-circle-hold {
    left: inherit;
    right: -100px;
  }

  .roadmap-circle-hold {
    background: url('../src/assets/img/dotted-circle.svg');
    height: 118px;
    width: 118px;
  }

  .roadmap-row:nth-child(odd) .roadmap-phase {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .roadmap-phase {
    height: 140px;
  }
}

.roadmap-phase {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    position: relative;
}

.roadmap-tl {
    max-width: 350px;
    width: 100%;
}


.roadmap-tl-phase {
    overflow: hidden;
    padding: 10px 0 50px;
    position: relative;
}

dd, ol, ul {
    list-style: none;
}


.roadmap-row:not(:last-child) {
    margin-bottom: 15px;
}

.roadmap-row {
    padding-left: 70px;
    position: relative;
}

.roadmap-row:nth-child(2n) .roadmap-circle-hold, .roadmap-row:nth-child(odd) .roadmap-circle-hold {
    left: 0;
}

.roadmap-circle-hold {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 57px;
    justify-content: center;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 57px;
    z-index: 5;
}

.token-address {
  word-break: break-all;
}


/*=============================
  11. RoadMap
===============================*/
.roadmap-area {
    overflow: hidden;
}
.roadmap-title {
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 45px;
    margin-left: 14px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
}
.roadmap-content {
    border: 1px solid rgba(43 44 56 / 46%);
    background: var(--tg-secondary-color);
    border-radius: 15px;
    padding: 35px 30px 35px;
}
.roadmap-content .title {
    display: flex;
    align-items: flex-start;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    gap: 16px;
}
.roadmap-content .title .dot {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: block;
    position: relative;
    background: rgba(255 255 255 / 11%);
    flex: 0 0 auto;
    transform: translateY(3px);
}
.roadmap-content .title .dot::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #5dfff5;
}
.roadmap-content .title .dot::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 35px;
    width: 1px;
    height: 80px;
    border-left-style: dashed;
    border-width: thin;
}
.roadmap-content p {
    margin-bottom: 0;
}
.roadMap-active .col-lg-4 {
    padding: 0 15px;
}

.tokenomics {
  background: #000;
}

.slick-dots li button:before {
  font-size: 12px !important;
  top: 10px !important;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #fff !important;
}

.roadmap-item-done{
  color: #5efef5 !important;
}

@media only screen and (max-width: 1000px) {
  .project .nav.nav-pills {
    width: 100%;
  }

  .project .nav.nav-pills .nav-link {
    font-size: 16px !important;
    padding: 17px 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .banner {
    padding: 100px 0px !important;
  }

  .floating-icon{
    width: 100%;
    max-width: 250px;
    margin: auto;
    margin-bottom: 50px;
  }

  .project p {
    width: 100%;
  }

  .banner h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .banner p {
    font-size: 1rem;
    width: 100%;
  }

  .project p {
    width: 100%;
    font-size: 1rem;
  }

  .project img{
    border-radius: 20px !important;
  }

  .skill-bx {
    background: #151515;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    margin-top: -60px;
  }

  .roadmap-title {
    margin-bottom: 25px 
  }

  .roadmap-content {
    padding: 25px 20px 25px;
  }

  .roadmap-content .title {
    margin-bottom: 1rem;
  }

  .roadmap-content .title .dot::after {
    height: 50px
  }

  .download-bx {
    padding: 20px 20px;
    border-radius: 20px;
  }

  .skill-slider {
    width: 100%;
  }

  .react-multiple-carousel__arrow--left {
    left: 0px !important;
  }

  .react-multiple-carousel__arrow--right {
    right: 0px !important;
  }

  .banner button {
    font-size: 12px !important;
  }

  .navbar-text button {
    padding: 5px 10px !important; 
  }

  .banner button {
    margin-top: 20px
  }
}

@media only screen and (max-width: 469px) {
  .project .nav.nav-pills .nav-link {
    font-size: 12px !important;
  }

  .project p {
    font-size: 14px !important;
  }

  .roadMap-active .col-lg-4 {
    padding: 0px 5px !important;
  }

  .roadmap-content p {
    font-size: 14px !important;
  }
}